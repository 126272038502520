<template>
    <div class="book animated fadeIn">
      <div class="body">
        <div class="row">
            <div class="col-md-6 col-6">
               <SidebarView title="Confident Cloud" previous="Books" previousLink="/books" />
            </div>
            <div class="col-md-6 col-6 text-right">
                <router-link to="/">
                    <img src="../assets/images/icon.jpg" class="myicon" />
                </router-link>
          </div>
        </div>
        <br /><br />
        <div class="row">
              <div class="col-md-1 col-sm-2"></div>
              <div class="col-md-10 col-sm-8">
                  <div class="row">
                      <div class="col-md-5 col-sm-12">
                          <img src="../assets/images/cc-mockup.png" style="width: 100%;" />    
                      </div>
                      <div class="col-md-1 col-sm-12"></div>
                      <div class="col-md-6 col-sm-12">
                          <h1 class="book-title">
                              CONFIDENT<br />
                              CLOUD
                          </h1>
                          <p>Uncover the Essentials of 
                            <br />Cloud Computing and Development</p>
                          <br />
                          <p class="purple">by Adora Nwodo</p>
                      </div>
                  </div>
                  <br /><br /><br />
                  <!--<div class="row">
                      <div class="col-sm-12 text-center">
                          <h4>Be the first to know when it's officially out:</h4>
                      </div>
                      <div class="col-sm-12 text-center">
                        <a class="btn preorder" href="https://forms.gle/SBcGPhVp9NPwsLhu6" target="_blank">Join the mailing list</a> 
                      </div>
                  </div>
                  <br /><br /><br />-->
                  <div class="row">
                      <div class="col-sm-12 text-center">
                          <h4>Buy Now On:</h4>
                      </div>
                      <div class="col-sm-12 text-center">
                        <img src="../assets/images/kp2.png" class="platform-logo" />
                        <img src="../assets/images/amazon.png" class="platform-logo" />
                        <img src="../assets/images/google-books.png" class="platform-logo" /> 
                        <img src="../assets/images/target.png" class="platform-logo" />
                        <img src="../assets/images/bookshop.png" class="platform-logo" />
                        <img src="../assets/images/bn.jpg" class="platform-logo" />
                        <img src="../assets/images/walmart.png" class="platform-logo" />
                        <img src="../assets/images/willowbrook.jpeg" class="platform-logo" />  
                      </div>
                  </div>
                  <br />
                  <div class="row">
                    <div class="col-sm-12 text-center">
                        <a class="btn preorder" href="https://www.koganpage.com/risk-compliance/confident-cloud-9781398615670" target="_blank">Buy on KoganPage</a>
                        <a class="btn preorder" href="https://www.amazon.co.uk/Confident-Cloud-Uncover-Essentials-Computing/dp/1398615676" target="_blank">Buy on Amazon</a>
                        <a class="btn preorder" href="https://books.google.com/books/about/Confident_Cloud.html?id=rYEl0AEACAAJ" target="_blank">Buy on Google Books</a>
                        <a class="btn preorder" href="https://www.target.com/p/confident-cloud-by-adora-nwodo-hardcover/-/A-89894711" target="_blank">Buy on Target</a>
                        <a class="btn preorder" href="https://bookshop.org/p/books/confident-cloud-uncover-the-essentials-of-cloud-computing/20569346" target="_blank">Buy on Bookshop.org</a>
                        <a class="btn preorder" href="https://www.barnesandnoble.com/w/confident-cloud-adora-nwodo/1144039958" target="_blank">Buy on Barnes & Noble</a>
                        <a class="btn preorder" href="https://www.walmart.com/ip/Confident-Confident-Cloud-Uncover-the-Essentials-of-Cloud-Computing-Series-17-Hardcover-9781398616240/5028624556" target="_blank">Buy on Walmart</a>
                        <a class="btn preorder" href="https://www.shopwillowbrook.com/storeyline/product/confident-cloud-by-adora-nwodo-hardcover-indigo-chapters-coles-728e9a" target="_blank">Buy on Willowbrook</a>
                    </div>
                  </div>
                  <br /><br />
                  <div class="row">
                      <div class="col-sm-2"></div>
                      <div class="col-sm-8">
                        <h2 class="quote text-center">"Cloud computing is empowering, as anyone can now access a wealth of computing resources from anywhere in the world." ~ Satya Nadella</h2>
                      </div>
                      <div class="col-sm-2"></div>
                  </div>
                  <br /><br />
                  <div class="row">
                      <div class="col-sm-1"></div>
                      <div class="col-sm-10">
                        <p>
                            94% of all enterprises depend upon cloud services. Gain the edge in your career with this essential guide to the concepts and platforms of cloud computing.
                        </p>
                        <p>
                            Cloud computing is part of our everyday lives, from cloud backups of personal data to cloud platforms powering businesses around the world. But do you really understand how cloud computing works? This comprehensive guide demystifies key concepts and gives you the confidence you need to succeed in this sector.
                        </p>
                        <p>
                            <strong>Confident Cloud</strong> includes key concepts from FinOps and DevOps to Kubernetes and automation. This is supported by practical guidance on working in the industry, alongside a variety of fascinating case studies, clarifying how cloud infrastructure is effectively deployed and maintained.
                        </p>
                          <ul class="what-to-learn">
                            <li>
                                Cloud Computing Architectures
                            </li>
                            <li>
                                Cloud Storage, Big Data, and Analytics in the Cloud
                            </li>
                            <li>
                                Platform Engineering
                            </li>
                            <li>
                                Cloud Security and Privacy
                            </li>
                            <li>
                                Cloud Cost Management
                            </li>
                            <li>
                                Navigating Cloud Careers (and more)
                            </li>
                          </ul>
                      </div>
                      <div class="col-sm-1"></div>
                  </div>
                  <div class="row">
                      <div class="col-md-12 text-center">
                          <br /><br />
                          <h3 class="purple">GET THIS BOOK NOW!</h3>
                      </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12 text-center">
                        <a class="btn preorder" href="https://www.koganpage.com/risk-compliance/confident-cloud-9781398615670" target="_blank">Buy on KoganPage</a>
                        <a class="btn preorder" href="https://www.amazon.co.uk/Confident-Cloud-Uncover-Essentials-Computing/dp/1398615676" target="_blank">Buy on Amazon</a>
                        <a class="btn preorder" href="https://books.google.com/books/about/Confident_Cloud.html?id=rYEl0AEACAAJ" target="_blank">Buy on Google Books</a>
                        <a class="btn preorder" href="https://www.target.com/p/confident-cloud-by-adora-nwodo-hardcover/-/A-89894711" target="_blank">Buy on Target</a>
                        <a class="btn preorder" href="https://bookshop.org/p/books/confident-cloud-uncover-the-essentials-of-cloud-computing/20569346" target="_blank">Buy on Bookshop.org</a>
                        <a class="btn preorder" href="https://www.barnesandnoble.com/w/confident-cloud-adora-nwodo/1144039958" target="_blank">Buy on Barnes & Noble</a>
                        <a class="btn preorder" href="https://www.walmart.com/ip/Confident-Confident-Cloud-Uncover-the-Essentials-of-Cloud-Computing-Series-17-Hardcover-9781398616240/5028624556" target="_blank">Buy on Walmart</a>
                        <a class="btn preorder" href="https://www.shopwillowbrook.com/storeyline/product/confident-cloud-by-adora-nwodo-hardcover-indigo-chapters-coles-728e9a" target="_blank">Buy on Willowbrook</a>
                    </div>
                  </div>
                  <br /><br />
                  <hr />
                  <br />
                  <div class="row">
                      <div class="col-md-12">
                          <br /><br />
                          <h3 class="purple text-center">ABOUT THE AUTHOR</h3>
                          <br /><br />
                      </div>
                      <div class="col-md-3 author-container">
                          <img src="../assets/images/nenne-min.jpg" class="full-width" />
                      </div>
                      <div class="col-md-9">
                        <p>
                              <router-link to="/" class="txt-black custom-link">Adora Nwodo</router-link> is an Engineering Manager currently working
                              at the intersection of Cloud Engineering and Developer Platforms. She is also the Founder of NexaScale, a non-profit helping people
                              start and scale their tech careers, and she is a Vice President of the Virtual Reality/Augmented Reality (VRAR)
                              Association – Nigeria chapter.
                              <br /><br />
                              With a passion for enabling women in technology, Adora publishes content to share her tech journey and teach
                              software engineering on her blog called AdoraHack.
                          </p>
                      </div>
                  </div>
              </div>
              <div class="col-md-1 col-sm-2"></div>
        </div>
        <br /><br /><br />
      </div>
      <FooterView />
    </div>
  </template>
  
  <script>
  import SidebarView from '@/components/SidebarView.vue' 
  import FooterView from '@/components/FooterView.vue' 
  
  export default {
      components: { 
          SidebarView,
          FooterView 
      }
  }
  </script>
  
  <style scoped>
  .book{
      width: 100%;
  }
  .what-to-learn{
    margin-left: 20px !important;
  }
  a.preorder.btn{
    font-family: 'Poppins', sans-serif; font-weight: 300 !important;
    font-size: 17px !important;
  }
  .full-width{
      width: 100%;
  }
  .platform-logo{
      height: 30px !important;
      margin: 10px 20px !important;
  }
  .book-mockup{
      margin-bottom: 20px;
  }
  .book .purple{
    color: rgb(215, 0, 215);
  }
  .book .orange{
      color: #FF914D;
  }
  .book .contributor{
      border-top-left-radius: 40px;
      border-bottom-right-radius: 40px;
      border: 4px dotted #FF914D;
      padding: 10px;
  }
  .book h5 a{
      font-weight: bolder !important;
      color: black !important;
  }
  .book ul li{
      margin-left: -20px;
      padding-bottom: 5px;
  }
  .contributor-container h5{
      margin-top: 15px !important;
  }
  .contributor-container p{
      margin-bottom: 25px !important;
  }
  .orange-bg{
      background: #FF914D !important;
  }
  .txt-black{
      color: black !important;
  }
  h3.break{
      font-size: 22px !important;
  }
  a.preorder{
      background: black;
      font-weight: 400 !important;
      padding: 10px 20px;
      border-radius: 20px;
      color: white;
      font-size: 18px !important;
      border: 2px solid black;
      margin-left: 10px;
      margin-right: 10px;
      margin-top: 20px;
  }
  a.preorder:hover{
      box-shadow: -4px 5px 17px -2px rgba(0,0,0,0.29);
      -webkit-box-shadow: -4px 5px 17px -2px rgba(0,0,0,0.29);
      -moz-box-shadow: -4px 5px 17px -2px rgba(0,0,0,0.29);
      background: white;
      color: black;
  }
  a.preorder-black{
      background: #000;
      font-weight: 400 !important;
      padding: 10px 20px;
      border-radius: 20px;
      color: white;
      font-size: 18px !important;
      border: 2px solid #000;
      margin-left: 10px;
      margin-right: 10px;
      margin-top: 20px;
  }
  a.preorder-black:hover{
      box-shadow: -4px 5px 17px -2px rgba(0,0,0,0.29);
      -webkit-box-shadow: -4px 5px 17px -2px rgba(0,0,0,0.29);
      -moz-box-shadow: -4px 5px 17px -2px rgba(0,0,0,0.29);
      background: white;
      color: #000;
  }
  .book .author-container img{
      padding-bottom: 30px;
  }
  .book h1.book-title span{
      font-weight: 900 !important;
  }
  .book h2.quote{
      font-family: 'Open Sans', sans-serif;
      color: #ddd;
      font-style: italic;
  }
  .book small{
      font-size: 11px;
  }
  .book p, .book .what-to-learn{
      font-size: 18px !important;
  }
  .book p.bolder{
      font-weight: 400 !important;
      color: black;
  }
  @media only screen and (min-width: 1200px) {
    .book h1.book-title{font-size: 45px !important; padding-top: 50px;}
  }
  @media only screen and (max-width: 1199px) {
    .book h1.book-title{font-size: 35px !important; padding-top: 30px;}
  }
  @media only screen and (max-width: 900px) {
    .book h1.book-title{font-size: 28px !important; padding-top: 35px;}
  }
  @media only screen and (max-width: 869px) {
    p{font-size: 11px !important;}
    .book h2.quote{font-size: 24px !important;}
  }
  @media only screen and (max-width: 767px) {
    .book h1.book-title{font-size: 50px !important; padding-top: 35px; text-align: center;}
    p{font-size: 20px !important; text-align: center;}
    p a.custom-link, .book h5 a{ font-size: 18px !important; }
  }
  @media only screen and (max-width: 599px) {
    .book h1.book-title{font-size: 50px !important; padding-top: 40px; text-align: center;}
    p a.custom-link, .book h5 a{ font-size: 18px !important; }
  }
  @media only screen and (max-width: 480px) {
    .book h1.book-title{font-size: 50px !important; padding-top: 30px; text-align: center;}
     p{font-size: 15px !important; text-align: center;}
     p a.custom-link, .book h5 a{ font-size: 18px !important; }
  }
  @media only screen and (max-width: 390px) {
    .book h1.book-title{font-size: 40px !important; padding-top: 30px; text-align: center;}
  }
  </style>
  