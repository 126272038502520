<template>
  <div class="books animated fadeIn">
    <div class="body">
      <div class="row">
          <div class="col-md-6 col-6">
             <SidebarView title="Books" single="true" />
          </div>
          <div class="col-md-6 col-6 text-right">
            <router-link to="/">
            <img src="../assets/images/icon.jpg" class="myicon" />
           </router-link>
        </div>
      </div>
      <br /><br />
      <div class="row">
        <div class="col-md-4 col-sm-6 item-container">
            <router-link to="/books/iac-with-azure-bicep" class="books-item">
              <div>
                <span>
                    <small>BOOK</small>
                </span>
                <p class="bolder">
                  Infrastructure as Code with Azure Bicep<br /><br />
                </p>
                <img src="../assets/images/bicep-iac.png" class="books-thumbnail"/>
              </div>
              </router-link>
          </div>
          <div class="col-md-4 col-sm-6 item-container">
            <a target="_blank" href="https://www.oreilly.com/library/view/microsoft-certified-azure/9781098173487/" class="books-item">
              <div>
                <span>
                    <small>BOOK</small>
                </span>
                <p class="bolder">
                  Microsoft Certified Azure Developer Associate (AZ-204) Study Guide<br />
                </p>
                <img src="../assets/images/az204-min.png" class="books-thumbnail"/>
              </div>
            </a>
          </div>
          <div class="col-md-4 col-sm-6 item-container">
            <router-link to="/books/confident-cloud" class="books-item">
              <div>
                <span>
                    <small>BOOK</small>
                </span>
                <p class="bolder">
                    Confident Cloud<br /><br />
                </p>
                <img src="../assets/images/confident-cloud-min.jpg" class="books-thumbnail"/>
              </div>
              </router-link>
          </div>
          <div class="col-md-4 col-sm-6 item-container">
            <router-link to="/books/beginning-azure-devops" class="books-item">
              <div>
                <span>
                    <small>BOOK</small>
                </span>
                <p class="bolder">
                    Beginning Azure DevOps<br /><br />
                </p>
                <img src="../assets/images/beginning-ado.jpg" class="books-thumbnail"/>
              </div>
              </router-link>
          </div>
          <div class="col-md-4 col-sm-6 item-container">
            <a target="_blank" href="https://adorahack.selar.co/devops-study-guide" class="books-item">
              <div>
                <span>
                    <small>BOOKLET</small>
                </span>
                <p class="bolder">
                    A Handy DevOps Roadmap and Study Guide<br /><br />
                </p>
                <img src="../assets/images/devops-roadmap.jpg" class="books-thumbnail"/>
              </div>
            </a>
          </div>
          <div class="col-md-4 col-sm-6 item-container">
              <router-link to="/books/cloud-engineering-for-beginners" class="books-item">
              <div>
                <span>
                    <small>BOOK</small>
                </span>
                <p class="bolder">
                    Cloud Engineering for Beginners <br /><br />
                </p>
                <img src="../assets/images/courses2.jpg" class="books-thumbnail"/>
              </div>
              </router-link>
          </div>
      </div>
    </div>
    <FooterView />
  </div>
</template>

<script>
import SidebarView from '@/components/SidebarView.vue' 
import FooterView from '@/components/FooterView.vue' 

export default {
    components: { 
        SidebarView,
        FooterView 
    }
}
</script>

<style scoped>
.books{
    width: 100%;
}
.books span{
    color: #df4aa8;
}
.books small{
    font-size: 11px;
}
.books p.bolder{
    font-weight: 400 !important;
    color: black;
}
.books .books-thumbnail{
    width: 100%;
}
.books .books-item{
    text-decoration: none !important;
}
.books .books-item:hover div{
    -webkit-box-shadow: 0px 0px 20px -13px rgba(0,0,0,0.7);
  -moz-box-shadow: 0px 0px 20px -13px rgba(0,0,0,0.7);
  box-shadow: 0px 0px 20px -13px rgba(0,0,0,0.7);
}
.books .books-item div{
    padding: 30px;
      -webkit-box-shadow: 0px 0px 20px -13px rgba(0,0,0,0.3);
  -moz-box-shadow: 0px 0px 20px -13px rgba(0,0,0,0.3);
  box-shadow: 0px 0px 20px -13px rgba(0,0,0,0.3);
  border-radius: 30px;
}
.books .item-container{
  margin-bottom: 40px;
}
</style>
