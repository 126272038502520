import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import App from './App.vue'
import HomeView from './views/HomeView.vue'
import AboutView from './views/AboutView.vue'
import PortfolioView from './views/PortfolioView.vue'
import CommunityView from './views/CommunityView.vue'
import VideosView from './views/VideosView.vue'
import BlogView from './views/BlogView.vue'
import TalksView from './views/TalksView.vue'
import PodcastsView from './views/PodcastsView.vue'
import FeaturesView from './views/FeaturesView.vue'
import AwardsView from './views/AwardsView.vue'
import BooksView from './views/BooksView.vue'
import CoursesView from './views/CoursesView.vue'
import CloudEngineeringForBeginners from './views/CloudEngineeringForBeginners.vue'
import BeginningAzureDevOps from './views/BeginningAzureDevOps.vue'
import ConfidentCloud from './views/ConfidentCloud.vue'
import IacWithAzureBicep from './views/IacWithAzureBicep.vue'
import ErrorView from './views/ErrorView.vue'
import Vue3Storage from "vue3-storage"
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

const routes = [
    { path: '/', component: HomeView },
    { path: '/about', component: AboutView },
    { path: '/portfolio', component: PortfolioView },
    { path: '/community', component: CommunityView },
    { path: '/videos', component: VideosView },
    { path: '/blog', component: BlogView },
    { path: '/talks', component: TalksView },
    { path: '/podcasts', component: PodcastsView },
    { path: '/features', component: FeaturesView },
    { path: '/awards', component: AwardsView },
    { path: '/books', component: BooksView },
    { path: '/courses', component: CoursesView },
    { path: '/books/cloud-engineering-for-beginners', component: CloudEngineeringForBeginners },
    { path: '/books/beginning-azure-devops', component: BeginningAzureDevOps },
    { path: '/books/confident-cloud', component: ConfidentCloud },
    { path: '/books/iac-with-azure-bicep', component: IacWithAzureBicep },
    { path: '/:pathMatch(.*)*', component: ErrorView }
]

const router = createRouter({
    history: createWebHistory(),
    routes,
});

const app = createApp(App);
app.use(router);
app.use(Vue3Storage);
app.mount('#app');