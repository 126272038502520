<template>
    <div class="book animated fadeIn">
      <div class="body">
        <div class="row">
            <div class="col-md-6 col-6">
               <SidebarView title="IaC with Azure Bicep" previous="Books" previousLink="/books" />
            </div>
            <div class="col-md-6 col-6 text-right">
                <router-link to="/">
                    <img src="../assets/images/icon.jpg" class="myicon" />
                </router-link>
          </div>
        </div>
        <br /><br />
        <div class="row">
              <div class="col-md-1 col-sm-2"></div>
              <div class="col-md-10 col-sm-8">
                  <div class="row">
                      <div class="col-md-5 col-sm-12">
                          <img src="../assets/images/bicep-2.png" style="width: 100%;" />    
                      </div>
                      <div class="col-md-1 col-sm-12"></div>
                      <div class="col-md-6 col-sm-12">
                          <h1 class="book-title">
                              INFRASTRUCTURE <br />
                              AS CODE WITH <br />
                              AZURE BICEP
                          </h1>
                          <p>Infrastructure development through Bicep modules, template specs, and DevOps automation pipelines
                            </p>
                          <br />
                          <p class="purple">by Adora Nwodo</p>
                      </div>
                  </div>
                  <br /><br /><br />
                  <div class="row">
                      <div class="col-sm-12 text-center">
                          <h4>Buy Now On:</h4>
                      </div>
                      <div class="col-sm-12 text-center">
                        <img src="../assets/images/amazon.png" class="platform-logo" />
                        <img src="../assets/images/bpb-logo.png" class="platform-logo" />  
                      </div>
                  </div>
                  <br />
                  <div class="row">
                    <div class="col-sm-12 text-center">
                        <a class="btn preorder" href="https://bpbonline.com/products/infrastructure-as-code-with-azure-bicep?variant=44517529485512" target="_blank">Buy on BPB</a>
                        <a class="btn preorder" href="https://www.amazon.com/Infrastructure-Code-Azure-Bicep-development/dp/9365891922/" target="_blank">Buy on Amazon</a>
                    </div>
                  </div>
                  <br /><br />
                  <div class="row">
                      <div class="col-sm-2"></div>
                      <div class="col-sm-8">
                        <h2 class="quote text-center">Azure Bicep is a powerful language for deploying Azure resources declaratively, providing a simpler and more efficient alternative to JSON for creating Azure Resource Manager templates.</h2>
                      </div>
                      <div class="col-sm-2"></div>
                  </div>
                  <br /><br />
                  <div class="row">
                      <div class="col-sm-1"></div>
                      <div class="col-sm-10">
                        <p>
                            Azure Bicep is a powerful language for deploying Azure resources declaratively, providing a simpler and more efficient alternative to JSON for creating Azure Resource Manager templates. It streamlines the process of defining, provisioning, and managing cloud infrastructure, making Infrastructure as Code (IaC) more accessible and effective.
                        </p>
                        <p>
                            This book provides a comprehensive guide to Azure Bicep, starting with an introduction to IaC, Bicep's core concepts, and a comparative analysis with ARM templates. It covers installation, setup, fundamental syntax, and practical template creation and deployment. Readers will learn to utilize variables, parameters, expressions, conditions, and loops for dynamic configurations. The book explores modularity through Bicep modules and template specs, as well as compilation and decompilation processes. Automation is emphasized with GitHub Actions and Azure DevOps CI/CD pipelines, including monitoring and troubleshooting. Advanced topics include scaling Bicep for large projects, secret management, cross-subscription deployments, and Policy as Code (PaC).
                        </p>
                        <p>
                            <strong>WHAT YOU WILL LEARN</strong> 
                        </p>
                          <ul class="what-to-learn">
                            <li>
                                Learn Azure Bicep from installation to advanced deployment automation with GitHub Actions and Azure DevOps.
                            </li>
                            <li>
                                Implement variables, parameters, and template functions for dynamic resource configurations across multiple deployment scenarios.
                            </li>
                            <li>
                                Compile, decompile, and migrate ARM templates to Bicep with effective dependency management.
                            </li>
                            <li>
                                Create reusable modules using template specs and private registries for maintainable infrastructure components.
                            </li>
                            <li>
                                Architect deployments with conditions, loops, and expressions optimized for large-scale enterprise environments.
                            </li>
                          </ul>
                      </div>
                      <div class="col-sm-1"></div>
                  </div>
                  <div class="row">
                      <div class="col-md-12 text-center">
                          <br /><br />
                          <h3 class="purple">GET THIS BOOK NOW!</h3>
                      </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12 text-center">
                        <a class="btn preorder" href="https://bpbonline.com/products/infrastructure-as-code-with-azure-bicep?variant=44517529485512" target="_blank">Buy on BPB</a>
                        <a class="btn preorder" href="https://www.amazon.com/Infrastructure-Code-Azure-Bicep-development/dp/9365891922/" target="_blank">Buy on Amazon</a>
                    </div>
                  </div>
                  <br /><br />
                  <hr />
                  <br />
                  <div class="row">
                      <div class="col-md-12">
                          <br /><br />
                          <h3 class="purple text-center">ABOUT THE AUTHOR</h3>
                          <br /><br />
                      </div>
                      <div class="col-md-3 author-container">
                          <img src="../assets/images/nenne-min.jpg" class="full-width" />
                      </div>
                      <div class="col-md-9">
                          <p>
                              <router-link to="/" class="txt-black custom-link">Adora Nwodo</router-link> is an Engineering Manager currently working
                              at the intersection of Cloud Engineering and Developer Platforms. She is also the Founder of NexaScale, a non-profit helping people
                              start and scale their tech careers, and she is a Vice President of the Virtual Reality/Augmented Reality (VRAR)
                              Association – Nigeria chapter.
                              <br /><br />
                              With a passion for enabling women in technology, Adora publishes content to share her tech journey and teach
                              software engineering on her blog called AdoraHack.
                          </p>
                      </div>
                  </div>
              </div>
              <div class="col-md-1 col-sm-2"></div>
        </div>
        <br /><br /><br />
      </div>
      <FooterView />
    </div>
  </template>
  
  <script>
  import SidebarView from '@/components/SidebarView.vue' 
  import FooterView from '@/components/FooterView.vue' 
  
  export default {
      components: { 
          SidebarView,
          FooterView 
      }
  }
  </script>
  
  <style scoped>
  .book{
      width: 100%;
  }
  .what-to-learn{
    margin-left: 20px !important;
  }
  a.preorder.btn{
    font-family: 'Poppins', sans-serif; font-weight: 300 !important;
    font-size: 17px !important;
  }
  .full-width{
      width: 100%;
  }
  .platform-logo{
      height: 30px !important;
      margin: 10px 20px !important;
  }
  .book-mockup{
      margin-bottom: 20px;
  }
  .book .purple{
    color: rgb(215, 0, 215);
  }
  .book .orange{
      color: #FF914D;
  }
  .book .contributor{
      border-top-left-radius: 40px;
      border-bottom-right-radius: 40px;
      border: 4px dotted #FF914D;
      padding: 10px;
  }
  .book h5 a{
      font-weight: bolder !important;
      color: black !important;
  }
  .book ul li{
      margin-left: -20px;
      padding-bottom: 5px;
  }
  .contributor-container h5{
      margin-top: 15px !important;
  }
  .contributor-container p{
      margin-bottom: 25px !important;
  }
  .orange-bg{
      background: #FF914D !important;
  }
  .txt-black{
      color: black !important;
  }
  h3.break{
      font-size: 22px !important;
  }
  a.preorder{
      background: black;
      font-weight: 400 !important;
      padding: 10px 20px;
      border-radius: 20px;
      color: white;
      font-size: 18px !important;
      border: 2px solid black;
      margin-left: 10px;
      margin-right: 10px;
      margin-top: 20px;
  }
  a.preorder:hover{
      box-shadow: -4px 5px 17px -2px rgba(0,0,0,0.29);
      -webkit-box-shadow: -4px 5px 17px -2px rgba(0,0,0,0.29);
      -moz-box-shadow: -4px 5px 17px -2px rgba(0,0,0,0.29);
      background: white;
      color: black;
  }
  a.preorder-black{
      background: #000;
      font-weight: 400 !important;
      padding: 10px 20px;
      border-radius: 20px;
      color: white;
      font-size: 18px !important;
      border: 2px solid #000;
      margin-left: 10px;
      margin-right: 10px;
      margin-top: 20px;
  }
  a.preorder-black:hover{
      box-shadow: -4px 5px 17px -2px rgba(0,0,0,0.29);
      -webkit-box-shadow: -4px 5px 17px -2px rgba(0,0,0,0.29);
      -moz-box-shadow: -4px 5px 17px -2px rgba(0,0,0,0.29);
      background: white;
      color: #000;
  }
  .book .author-container img{
      padding-bottom: 30px;
  }
  .book h1.book-title span{
      font-weight: 900 !important;
  }
  .book h2.quote{
      font-family: 'Open Sans', sans-serif;
      color: #ddd;
      font-style: italic;
  }
  .book small{
      font-size: 11px;
  }
  .book p, .book .what-to-learn{
      font-size: 18px !important;
  }
  .book p.bolder{
      font-weight: 400 !important;
      color: black;
  }
  @media only screen and (min-width: 1200px) {
    .book h1.book-title{font-size: 45px !important; padding-top: 50px;}
  }
  @media only screen and (max-width: 1199px) {
    .book h1.book-title{font-size: 35px !important; padding-top: 30px;}
  }
  @media only screen and (max-width: 900px) {
    .book h1.book-title{font-size: 28px !important; padding-top: 35px;}
  }
  @media only screen and (max-width: 869px) {
    p{font-size: 11px !important;}
    .book h2.quote{font-size: 24px !important;}
  }
  @media only screen and (max-width: 767px) {
    .book h1.book-title{font-size: 50px !important; padding-top: 35px; text-align: center;}
    p{font-size: 20px !important; text-align: center;}
    p a.custom-link, .book h5 a{ font-size: 18px !important; }
  }
  @media only screen and (max-width: 599px) {
    .book h1.book-title{font-size: 50px !important; padding-top: 40px; text-align: center;}
    p a.custom-link, .book h5 a{ font-size: 18px !important; }
  }
  @media only screen and (max-width: 480px) {
    .book h1.book-title{font-size: 50px !important; padding-top: 30px; text-align: center;}
     p{font-size: 15px !important; text-align: center;}
     p a.custom-link, .book h5 a{ font-size: 18px !important; }
  }
  @media only screen and (max-width: 390px) {
    .book h1.book-title{font-size: 40px !important; padding-top: 30px; text-align: center;}
  }
  </style>
  